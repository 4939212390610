<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <b-card>

      <b-row>
        <b-col lg="6">
          <h3>กติกาการเล่น</h3>
        </b-col>
      </b-row>

      <b-form-group
        label="หวย"
        label-for="customDelimiter"
      >
        <v-select
          v-model="LottoTypeID"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="name"
          :options="ListSelect"
          :reduce="ele => ele.id"
          @input=" ClearItems(), CheckEdit()"
        />
      </b-form-group>

    </b-card>
    <b-card
      v-if="LottoTypeID"
      no-body
    >
      <b-card-body>
        <div>
          <b-row>
            <b-col
              md="9"
              class="flex justify-content-start"
            />
            <b-col md="3">
              <div class="d-flex justify-content-end">
                <b-button
                  variant="primary"
                  block
                  @click="Submit()"
                >
                  บันทึก
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="mt-2">
          <quill-editor
            v-model="content"
            :options="snowOption"
          />
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'

import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BOverlay,
  BRow,
  BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BOverlay,
    quillEditor,
    BButton,
    BCol,
    BRow,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      show: false,
      content: '', // ตัวแปรข้อความ html
      EditID: null,
      LottoTypeID: null,
      ListSelect: [],
    }
  },
  mounted() {
    this.GetSublist()
  },
  methods: {
    async GetSublist() {
      try {
        this.show = true
        const { data: Res } = await this.$http.get('Auto/LottoList')
        // eslint-disable-next-line
        for (const x in Res) {
          this.ListSelect.push({
            id: Res[x].id,
            name: Res[x].name,
          })
        }
        this.show = false
      } catch (e) {
        console.log(e)
        this.SwalError('เกิดข้อผิดพลาดในการบันทึกข้อความ')
        this.show = false
      }
    },
    async Submit() {
      this.show = true
      console.log(this.content)
      try {
        const obj = {
          LottoTypeID: this.LottoTypeID,
          rules: this.content,
        }
        await this.$http.post('company/lottotype/save_rules', obj)
        this.Success('บันทึกข้อความเรียบร้อยแล้ว')
        this.show = false
      } catch (e) {
        console.log(e)
        this.SwalError('เกิดข้อผิดพลาดในการบันทึกข้อความ')
        this.show = false
      }
    },
    async CheckEdit() {
      if (this.LottoTypeID) {
        try {
          this.show = true
          const params = {
            ID: this.LottoTypeID,
          }
          const { data: Res } = await this.$http.get('company/lottotype/showbyid', { params })
          // console.log(Res)
          this.show = false
          if (Res.rules) {
            this.content = Res.rules
          }
        } catch (e) {
          console.log(e)
          this.SwalError('เกิดข้อผิดพลาดในการบันทึกข้อความ')
          this.show = false
        }
      }
    },
    ClearItems() {
      this.content = null
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
